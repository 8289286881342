import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { PartnerWebservice } from '@webservices/partner-api/partner.webservice';
import { ReplaySubject, combineLatest, switchMap } from 'rxjs';

export type Direction = 'asc' | 'desc';
export type Active = (typeof VERSIONS_SORT_PROPERTIES)[number];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VERSIONS_SORT_PROPERTIES = ['createdAt', 'status', 'id', 'author', 'revisionNumber'] as const;

@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  readonly #partnerId$ = new ReplaySubject<string>();
  readonly #offset$ = new ReplaySubject<number>();

  readonly #getRevisions$ = inject(PartnerWebservice).getRevisions;
  readonly #size = 20;

  set partnerId(id: string | null) {
    if (typeof id !== 'string') return;
    this.#partnerId$.next(id);
  }

  set offset(page: string | null) {
    const offset = page === null ? 0 : (Number(page) - 1) * this.#size;
    this.#offset$.next(offset);
  }

  readonly #versions$ = combineLatest([this.#partnerId$, this.#offset$]).pipe(
    switchMap(([partnerId, offset]) =>
      this.#getRevisions$({
        partnerId,
        offset,
        sort: 'revisionNumber',
        order: 'desc',
        size: this.#size,
      })
    )
  );

  readonly #versions = toSignal(this.#versions$, { initialValue: null });

  public readonly rows = computed(() => {
    const versions = this.#versions();
    if (versions === null) return [];

    return versions.results.map((result) => {
      const { id, status, partner } = result;
      return {
        id,
        status,
        author: partner.revision?.author ?? '-',
        date: partner.revision?.date ?? '-',
        number: partner.revision?.number ?? '-',
      };
    });
  });

  public readonly pagination = computed(() => {
    const versions = this.#versions();

    if (versions === null) return null;

    const { currentPage, pageSize: itemsPerPage, totalResults } = versions;

    const maxResults = 100; //README: maxResults limits results, greatest numbers are not valid for the API
    const itemsCount = totalResults >= maxResults ? maxResults : totalResults;

    return {
      itemsCount,
      currentPage: currentPage - 1,
      itemsPerPage,
    };
  });
}
